<template>
  <div id="banner" :style="`height: ${height}px`">
    <!-- <BannerCarousels :data="bannerItems" @scroll="$emit('scroll')" /> -->
    <el-carousel :height="height + 'px'">
      <el-carousel-item v-for="(item, index) in bannerItems" :key="index">
        <el-image
          style="width: 100%; height: 100%; cursor: pointer;"
          :src="item.src"
          fit="cover"
          @click="toPath(item.url)"
        ></el-image>
      </el-carousel-item>
      <!--      <el-carousel-item>-->
      <!--        <el-image-->
      <!--          style="width: 100%; height: 100%; cursor: pointer;"-->
      <!--          :src="bannerItems[1].src"-->
      <!--          fit="fill"-->
      <!--          @click="$emit('scroll')"-->
      <!--        ></el-image>-->
      <!--      </el-carousel-item>-->
    </el-carousel>
    <!-- 轮播背景 -->
  </div>
</template>

<script>
// import BannerCarousels from "@/components/Home/BannerCarousels";
import { getConfig } from "@/api/category";

export default {
  name: "HomeBanner",
  components: {
    // BannerCarousels
  },
  data() {
    return {
      bannerItems: [
        {
          id: 1,
          // title: "简单，信任，快乐",
          // description:
          //   "长益基金会致力于人与人之间的连接，减少冷漠、消除隔阂，构建简单、信任、快乐的人性生态。",
          // btn: "了解更多",
          url: "/about-us/whoweare",
          src: require("../../assets/images/rotation-chart1.jpeg")
        },
        {
          id: 2,
          // title: "老吾老以及人之老",
          // description:
          //   "长益基金会聚焦养老服务，专注于“关怀山村留守老人”公益项目。",
          // btn: "了解更多",
          url: "/",
          src: require("../../assets/images/rotation-chart2.jpeg")
        }
      ],
      height: 800
    };
  },
  mounted() {
    window.onresize = () => {
      this.getHeight();
    };
    this.getHeight();
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const res = await getConfig("CarouselConfig");
      if (res !== null) {
        try {
          this.bannerItems = JSON.parse(res.value);
        } catch (e) {
          this.bannerItems = [];
        }
      }
    },
    getHeight() {
      const height = document.body.clientWidth / 3;
      if (height > 800) {
        this.height = 800;
      } else if (height < 420) {
        this.height = 420;
      } else {
        this.height = height;
      }
    },
    toPath(url) {
      if(url) {
        this.$router.push({ path: url });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  width: 80%;
  background-size: cover;
  overflow: hidden;
  margin: 40px auto 0;
}
</style>
