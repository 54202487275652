<template>
  <div class="video-player-wrapper" v-if="visible" @touchmove.prevent>
    <div class="toolbar">
      <svg-icon icon-class="close" class="btn-close" @click="handleClose" />
    </div>
    <iframe
      frameborder="0"
      :src="source"
      allowFullScreen="true"
      class="player"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      source: ""
    };
  },
  watch: {
    visible: {
      handler(visible) {
        //  直接修改style在IE下会报错，需要改成style.xxx
        if (visible) {
          // document.querySelector("html").style = "overflow: hidden";
          document.querySelector("html").style.overflow = "hidden";
        } else {
          // document.querySelector("html").style = "";
          document.querySelector("html").style.overflow = "unset";
        }
      },
      immediate: true
    }
  },
  methods: {
    async play(url) {
      this.source = null;
      this.visible = true;
      this.source = url;
    },
    handleClose() {
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.video-player-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.527);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .toolbar {
    width: 95%;
    height: 80px;
    max-width: 1920px;
    text-align: right;
    margin-bottom: 10px;

    .btn-close {
      height: 100%;
      color: rgb(255 255 255 / 52%);
      cursor: pointer;

      &:hover {
        color: rgb(255 255 255 / 88%);
      }
    }
  }

  .player {
    width: 95%;
    height: 90%;
    max-width: 1920px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.178);
  }
}
</style>
