<template>
  <div class="videos-wrapper">
    <!-- video-player -->
    <video-player ref="videoPlayer" />
    <!-- banner -->
    <!-- <div class="videos-wrapper"> -->
    <div class="videos">
      <div class="left" @click="handlePlay(formatConfig('videoUrl1'))">
        <img :src="formatConfig('videoBg1')" alt="" />
        <div class="wrapper">
          <div class="title">{{ formatConfig("videoTitle1") }}</div>
          <div class="btn">
            <img src="../../assets/images/play-video.png" alt="" />
          </div>
        </div>
      </div>
      <div class="right" @click="handlePlay(formatConfig('videoUrl2'))">
        <img :src="formatConfig('videoBg2')" alt="" />
        <div class="wrapper">
          <div class="title">{{ formatConfig("videoTitle2") }}</div>
          <div class="btn">
            <img src="../../assets/images/play-video.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";
import { getConfig } from "@/api/category";
import { formatConfig } from "@/utils/formatConfig";

export default {
  name: "Video",
  components: {
    VideoPlayer
  },
  data() {
    return {
      video_urls: [
        "https://v.qq.com/txp/iframe/player.html?vid=k3502hdumpp",
        "https://v.qq.com/txp/iframe/player.html?vid=t3502htl1f9"
      ],
      data: []
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    async handlePlay(url) {
      this.$refs.videoPlayer.play(url);
    },
    async getConfig() {
      const res = await getConfig("HomeVideo");
      if (res !== null) {
        try {
          this.data = JSON.parse(res.value);
        } catch (e) {
          this.data = [];
        }
      }
    },
    formatConfig(key, data = this.data) {
      return formatConfig(data, key);
    }
  }
};
</script>

<style lang="scss" scoped>
.videos-wrapper {
  width: 100%;
}
.videos {
  width: 100%;
  // height: 100%;
  height: 240px;
  display: flex;
  color: #fff;
  font-size: 24px;
  overflow: hidden;
  position: relative;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .video {
      position: absolute;
      z-index: -1;
    }
    .wrapper {
      width: 100%;
      height: 100%;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: rgba(#000000, 0.6);
      .btn {
        width: 44px;
        height: 44px;
        margin-top: 30px;
        z-index: 100;
        &:hover {
          transform: scale(1.1);
        }
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .left {
    height: 100%;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 30%;
    }
  }
  .right {
    height: 100%;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 55%;
    }
  }
}
</style>
