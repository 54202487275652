<template>
  <div class="wrapper">
    <div class="lunbo">
      <ul :style="{ left: current * -1024 + 'px' }">
        <li v-for="(item, index) in data" :key="index">{{ item }}</li>
      </ul>
    </div>
    <div
      class="btn left"
      @click="switchToPre(-1)"
      :class="{ unclickable: current == 0 }"
    >
      <img src="../../assets/images/left-full-blue.png" alt="" />
    </div>
    <div
      class="btn right"
      @click="switchToNext(1)"
      :class="{ unclickable: current == data.length - 1 }"
    >
      <img src="../../assets/images/right-full-blue.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousels",
  props: ["data"],
  data() {
    return {
      current: 0,
      lists: ["page1", "page2", "page3"]
    };
  },
  methods: {
    switchToNext() {
      if (this.current == this.data.length - 1) {
        this.current = 0;
        return;
      }
      this.current += 1;
    },
    switchToPre() {
      if (this.current == 0) {
        this.current = this.data.length - 1;
        return;
      }
      this.current -= 1;
    }
  },
  mounted() {
    setInterval(() => {
      this.switchToNext();
    }, 20000);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
* {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* 1024 165 */
.wrapper {
  width: 1250px;
  position: relative;
  margin: 20px auto 70px;
}
.lunbo {
  width: 1024px;
  height: 165px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
ul {
  width: 9000px;
  position: absolute;
  left: 0;
  transition: all ease 1s;
  padding: 0;
}
li {
  float: left;
  width: 1024px;
  height: 165px;
  display: flex;
  align-items: center;
  text-align: start;
  font-size: 20px;
  line-height: 39px;
}
.btn {
  width: 44px;
  height: 44px;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -22px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.btn.left {
  left: 0;
}
.btn.right {
  right: 0;
}
</style>
