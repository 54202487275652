<template>
  <div class="project">
    <!-- 文字 -->
    <div class="slogan">
      <div>公益项目</div>
      <div>
        <!-- 这个是初期基于关怀老人提出的口号，以被公益和云南有所采用，但后来因为理事会觉得这不是针对机构理念的，开始淡化。 -->
      </div>
    </div>
    <!-- tab -->
    <div class="tab-wrapper">
      <!-- logo background -->
      <router-link class="more" :to="{ path: '/projects' }">更多+</router-link>
      <!-- <div ></div> -->
      <img src="../../assets/images/logo-bg.png" alt="" class="logo-bg" />
      <v-tabs v-model="tab" class="nav">
        <v-tab v-for="item in data" :key="item.id" class="item">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="content">
        <v-tab-item v-for="item in data" :key="item.id" class="item">
          <div class="img">
            <router-link
              class="link"
              :to="{ name: 'ProjectDetails', params: { id: item.id } }"
            >
              <img :src="item.img" alt="" />
            </router-link>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { getHomeProject } from "@/api/frontArticle";

export default {
  name: "Project",
  data() {
    return {
      tab: null,
      img: require("../../assets/images/project1.jpg"),
      Projects: [
        {
          id: 1,
          tab: "乡村助老员支持计划",
          content: "乡村助老员支持计划",
          icon: require("../../assets/images/xm-icon-1.png"),
          img: require("../../assets/images/plan.jpeg")
        },
        {
          id: 2,
          tab: "小红花梦想",
          content: "小红花梦想",
          icon: require("../../assets/images/xm-icon-2.png"),
          img: require("../../assets/images/dream.jpeg")
        },
        {
          id: 3,
          tab: "用心聆听·为爱奔跑",
          content: "用心聆听·为爱奔跑",
          icon: require("../../assets/images/xm-icon-3.png"),
          img: require("../../assets/images/running.jpeg")
        },
        {
          id: 4,
          tab: "科技助老",
          content: "科技助老",
          icon: require("../../assets/images/xm-icon-4.png"),
          img: require("../../assets/images/technology.jpeg")
        }
        // {
        //   tab: "上海市",
        //   content: "上海市",
        //   icon: require("../../assets/images/xm-icon-5.png")
        //   // img: require("../../assets/images/project1.png")
        // }
      ],
      data: []
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    switchItem(direction) {
      this.tab += direction;
      if (this.tab < 0) {
        this.tab = 4;
      }
    },
    async getConfig() {
      const res = await getHomeProject();
      this.data = res.map(o => {
        return {
          ...o,
          img: o.coverImg
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.project {
  width: 100%;
  position: relative;
  // margin-top: 40px;
  // .content {
  // overflow: visible;
  // }
  .tab-wrapper {
    position: relative;
  }
  .logo-bg {
    width: 492px;
    height: 492px;
    position: absolute;
    top: 90px;
    left: -300px;
    z-index: 1;
  }
}
.slogan {
  text-align: center;
  margin: 40px auto 30px;
  :first-child {
    font-size: 30px;
    color: #333333;
  }
  :last-child {
    font-size: 14px;
    color: #999999;
    margin: 20px 50px;
  }
}
.tab-wrapper {
  width: 1110px;
  margin: 40px auto 40px;
  // 更多
  .more {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
    position: absolute;
    font-size: 14px;
    line-height: 48px;
    right: 0;
    z-index: 900;
  }
  .nav {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: center;
    // padding: 0 200px;
    position: relative;
    overflow: visible !important;
    .item {
      font-size: 14px;
      color: #333;
      width: 175px;
    }
  }

  .content {
    height: 578px;
    position: relative;

    .item {
      height: 100%;
    }
    .main {
      width: 500px;
      height: 244px;
      background-color: #004898;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -122px;
      z-index: 100;
      color: #fff;
      .data-wrapper {
        width: 100%;
        height: 100%;
        padding: 26px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        justify-content: center;
        .title {
          display: flex;
          font-size: 20px;
          img {
            margin-right: 16px;
          }
        }
        .description {
          font-size: 14px;
          color: #8ea4bd;
          margin-top: 16px;
        }
        .data {
          width: 100%;
          padding: 0 60px;
          display: flex;
          justify-content: space-between;
          .data-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div:first-child {
              font-family: impact;
              font-size: 40px;
            }
            div:last-child {
              font-size: 14px;
            }
          }
        }
      }
      // 箭头
      .arrow {
        width: 50px;
        height: 50px;
        position: absolute;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .arrow:hover {
        background-color: rgba(#000000, 0.3);
      }
      .arrow.left {
        top: 50%;
        left: 25px;
        margin-top: -25px;
      }
      .arrow.right {
        top: 50%;
        right: 25px;
        margin-top: -25px;
      }
    }
    .img {
      width: 854px;
      height: 454px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -228px;
      // background-color: antiquewhite;
      overflow: hidden;
      z-index: 50;
      img {
        width: 100%;
      }
    }
  }
}
.link {
  text-decoration: none !important;
  color: #ffffff !important;
}
</style>
