<template>
  <div class="news">
    <div class="news-heading">
      <div class="news-heading-title">新闻资讯</div>
      <!-- <div> -->
      <!-- 我们应该大声表达我们为什么要这样做，让大家明白这些公益项目的必要性，这样就如同发出倡议，才能形成公益事业的合力。 -->
      <!-- </div> -->
    </div>
    <div class="wrapper">
      <div class="item" v-for="(item, index) in data" :key="index">
        <!-- 链接 -->
        <router-link
          class="link"
          :to="{ name: 'Details', params: { id: item.id } }"
        >
          <!-- 更多新闻 -->
          <!-- <div class="more-news" v-if="index == news.length - 1">
            <div>更多新闻</div> -->
          <!-- <i class="iconfont icon-right"></i> -->
          <!-- <span class="iconfont right-arrow">&#xe67c;</span>
          </div> -->
          <img :src="item.img" alt="" />
          <div class="date" :class="{ today: index == 0 }">
            <div>{{ item.date.split(".")[2] }}</div>
            <div>
              {{ item.date.split(".")[0] + "." + item.date.split(".")[1] }}
            </div>
          </div>
          <div class="news-title">
            <!-- <router-link class="link" :to="{ name: 'List' }"></router-link> -->
            <div>{{ item.title }}</div>
            <div>
              <img src="../../assets/images/right-arrow-white.png" alt="" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomePageNews } from "@/api/frontArticle";
import moment from "moment";

export default {
  name: "News",
  data() {
    return {
      news: [
        {
          id: 1,
          date: "2023.02.08",
          title:
            "2022年度上海市精神卫生中心表彰大会，长益基金会获颁“600号”优秀合作伙伴",
          img: require("../../assets/images/home-new1.jpeg"),
          url: "https://mp.weixin.qq.com/s/GldF2nKgnJpIRPuuMfjx_A"
        },
        {
          id: 2,
          date: "2023.02.03",
          title:
            "6年公益长跑，共创农村养老，请看CCTV专题纪录片《深山里的守候》",
          img: require("../../assets/images/home-new2.jpeg"),
          url: "https://mp.weixin.qq.com/s/axD5g7ZkjNksjDCnfKmniA"
        },
        {
          id: 3,
          date: "2022.12.31",
          title: "凡人微光，星火成炬——长益基金会2023年新年献词",
          img: require("../../assets/images/home-new3.jpeg"),
          url: "https://mp.weixin.qq.com/s/dfOXWeKQ6UIjGPtYweCd1g"
        },
        {
          id: 4,
          date: "2022.12.28",
          title: "第一届理事会第八次会议圆满召开",
          img: require("../../assets/images/home-new4.jpeg"),
          url: "https://mp.weixin.qq.com/s/2W1y21dHNZo8SEbl_KoU6w"
        },
        {
          id: 5,
          date: "2022.12.12",
          title:
            "戈十七一路益起“关怀乡村留守老人”项目获得“可持续发展项目”大奖！",
          img: require("../../assets/images/home-new5.jpeg"),
          url: "https://mp.weixin.qq.com/s/U_-JJqCjWsXAutAqJspHbg"
        },
        {
          id: 6,
          date: "2022.12.12",
          title: "祝贺长益基金会理事郭华伟女士当选农工党中央委员",
          img: require("../../assets/images/home-new6.jpeg"),
          url: "https://mp.weixin.qq.com/s/dM-8fF_Yxh96K5lmgtrBTQ"
        }
      ],
      data: []
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const res = await getHomePageNews();
      this.data = res.map(o => {
        return {
          ...o,
          img: o.coverImg,
          date: moment(o.date).format("YYYY.MM.DD")
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.news {
  margin-top: 40px;
  margin-bottom: 30px;
  .more-news {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 200;
    left: 0;
    top: 0;
    background-color: rgba($color: #004898, $alpha: 0.73);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 28px;
    z-index: 10;
    cursor: pointer;
    .right-arrow {
      margin-left: 30px;
      font-size: 24px;
    }
  }
  .more-news:hover {
    background-color: rgba($color: #004898, $alpha: 0.8);
  }

  .news-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      font-size: 30px;
      color: #333333;
    }
    // div:first-child {
    //   font-size: 30px;
    //   color: #333333;
    // }
    // div:last-child {
    //   font-size: 14px;
    //   color: #999999;
    //   margin: 20px 0 50px 0;
    // }
  }
  .wrapper {
    width: 1142px;
    margin: 20px auto 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      cursor: pointer;
      width: 344px;
      height: 215px;
      margin: 27px 16px;
      position: relative;
      overflow: hidden;
      // .link {
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: 100;
      // }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
      }
      .date {
        width: 70px;
        height: 76px;
        padding: 10px 0 8px 0;
        background-color: #004898;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        :first-child {
          font-size: 30px;
          line-height: 30px;
        }
        :last-child {
          font-size: 14px;
          line-height: 14px;
        }
      }
      .date.today {
        background-color: #fe7022;
      }
      .news-title {
        // width: 100%;
        height: 47px;
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        padding: 0 0 0 22px;
        font-size: 14px;
        z-index: 200;
        div:last-child {
          width: 47px;
          height: 47px;
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          img {
            // position: absolute;
            // right: 0;
            width: 47px;
            height: 47px;
            transform: scale(0.7);
          }
        }
        div:last-child:hover {
          background-color: rgba($color: #000000, $alpha: 0.7);
        }
      }
    }
  }
}
</style>
