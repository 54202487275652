<template>
  <div class="home">
    <!-- 1 banner -->
    <Banner @scroll="scroll" class="banner" />
    <!-- 2 捐赠金额 -->
    <!-- <Donation /> -->
    <!-- 3 公益项目 -->
    <Project class="project" />
    <!-- 4 视频 -->
    <Video id="video" />
    <!-- 5 新闻资讯 -->
    <News />
    <!-- 6 我们的团队 -->
    <!-- <Team /> -->
    <!-- 7 我们的伙伴 -->
    <Partner />
  </div>
</template>

<script>
import Banner from "./Banner";
// import Donation from "./Donation";
import Project from "./Project";
import Video from "./Video";
import News from "./News";
// import Team from "./Team";
import Partner from "./Partner";

export default {
  name: "Home",
  components: {
    Banner,
    // Donation,
    Project,
    Video,
    News,
    // Team,
    Partner
  },
  data() {
    return {
      // 1 banner 部分的数据
      // 2 捐赠
      // 3 项目
      // 4 新闻
      // 5 我们的团队
      // 6 我们的伙伴
    };
  },
  methods: {
    scroll() {
      document
        .querySelector("#video")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/var.scss";
.home {
  color: #1d1d1f;
}

// 1 banner

// 2 捐赠

// 3 公益项目

// 4 视频

// 5 新闻

// 6 我们的团队

// 7 我们的伙伴
// @media screen and (max-width: 2000px) {
//   .banner {
//     transform: scale(0.9, 0.9);
//     transform-origin: center 0%;
//   }
//   .project {
//     margin-top: -200px;
//   }
// }
// @media screen and (max-width: 1920px) {
//   .banner {
//     transform-origin: center 0%;
//     transform: scale(0.8, 0.8);
//   }
//   .project {
//     margin-top: -250px;
//   }
// }
// @media screen and (max-width: 1720px) {
//   .banner {
//     transform: scale(0.7, 0.7);
//     transform-origin: center 0%;
//   }
//   .project {
//     margin-top: -300px;
//   }
// }
// @media screen and (max-width: 1600px) {
//   .banner {
//     transform: scale(0.6, 0.6);
//     transform-origin: center 0%;
//   }
//   .project {
//     margin-top: -350px;
//   }
// }
</style>
