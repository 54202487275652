<template>
  <div class="partner">
    <div class="partner-wrapper">
      <div class="partner-title">我们的伙伴</div>
      <!-- <div class="description">
        为需要帮助的老人提供救助平台，并在慈善人士和求助者之间搭建爱心桥梁，让向善者明路，让无力者前行。
      </div> -->
      <!-- 轮播 -->
      <PartnerCarousels :data="partners" />
    </div>
  </div>
</template>

<script>
import PartnerCarousels from "@/components/Home/PartnerCarousels.vue";
import { getConfig } from "@/api/category";
import { formatConfig } from "@/utils/formatConfig";

export default {
  name: "Partner",
  components: {
    PartnerCarousels
  },
  data() {
    return {
      model: 0,
      partners: []
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const res = await getConfig("HomePartners");
      if (res !== null) {
        try {
          this.partners = JSON.parse(res.value);
        } catch (e) {
          this.partners = [];
        }
      }
    },
    formatConfig(key, data = this.data) {
      return formatConfig(data, key);
    }
  }
};
</script>

<style lang="scss" scoped>
.partner {
  width: 100%;
  background-image: url(../../assets/images/naipudaqiao2.png);
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  .partner-wrapper {
    margin: 50px auto;
    text-align: center;
    color: #333;
    .partner-title {
      text-align: center;
      font-size: 30px !important;
    }
    .description {
      font-size: 14px !important;
      margin-top: 20px;
    }
  }
  // 轮播
}
</style>
